import "../styles/Sidebar.css"
import { NavLink } from "react-router-dom"
import { LuMenu } from "react-icons/lu";
import { IoClose} from "react-icons/io5";
import { TbLayoutDashboardFilled } from "react-icons/tb";
import { MdPayments } from "react-icons/md";
import { FaUsers } from "react-icons/fa6";
import { FaUser } from "react-icons/fa";
import { BiSolidCopy } from "react-icons/bi";

import { useDispatch, useSelector } from 'react-redux';
import { setWalletAddress, disconnectWallet } from '../redux/walletSlice';
import { useContext, useEffect, useState } from "react";
import UserContext from '../context/UserContext';

const Sidebar = () => {
    const dispatch = useDispatch();

    const activeStyle = {
        color: "black",
        backgroundColor: "white",
      };
    const [showNav, setShowNav] = useState(false)
    const [showMenu, setShowMenu] = useState(false)
    const { walletAddress,syncWallet, unsyncWallet, setWeb3Prop } = useContext(UserContext);

    // Access the user data from Redux state
    const userData = useSelector((state) => state.wallet.userData);
    const walletBalance = userData?.lbt ?? '0.00000'; // Fallback balance if userData or lbt is not available

    useEffect(() => {
        setShowMenu(false)
    }, [])

    const handleLinkClick = () => {
        if (window.innerWidth <= 1000) {
            setShowNav(false);
        }
    };

    // Handle wallet disconnection
    const handleDisconnect = () => {
    unsyncWallet();
        // Update Redux state
    dispatch(disconnectWallet());
    };

    return<>
    {/* <div className="responsiveSidebar bg-white">
        <img src={require("../assets/images/dgenesis-logo.png")} alt="" />
        <div className="menuWrap">
            {
                showNav && window.innerWidth <= 1000? 
                    <IoClose  onClick={() => setShowNav(false)}/>
                :
                    <LuMenu  onClick={() => setShowNav(true)}/>
            }
        </div>
    </div> */}
    <div className="sidebarNavWrapper">
        <div className="sidebarNavLogo">
            <img src={require("../assets/images/dgenesis-logo.png")} alt="" />
        </div>
        <div className="sidebarNav">
            <div className="walletBrief walletBrief2">
                <img src={require("../assets/images/Wallet Coin.png")} alt="" />
                <span>Wallet balance</span>
                <p>{parseFloat(walletBalance).toFixed(5)} <small>LBT</small></p>
            </div>
            <div className="walletBrief">
                <img src={require("../assets/images/addressImage.png")} alt="" />
                <p>{walletAddress.substring(0, 9)} {walletAddress.length >= 9 && '...'}</p>

            </div>
            <button onClick={handleDisconnect}>Logout</button>
            <div className="menu">
                {
                    showNav && window.innerWidth <= 1000? 
                        <IoClose  onClick={() => setShowNav(false)}/>
                    :
                        <LuMenu  onClick={() => setShowNav(true)}/>
                }
            </div>
        </div>
    </div>
        <div className={(showNav && window.innerWidth <= 1000) || window.innerWidth > 1000  ? "sidebar" : "hide" }>
            <div className="topSidebar">
                <div className="logo">
                    <img src={require("../assets/images/dgenesis-logo.png")} alt="" />
                </div>

                <div className="navigations">
                    <NavLink
                        to={"/dashboard"}
                        style={({ isActive }) => (isActive ? activeStyle : undefined)}
                        end
                        onClick={handleLinkClick}
                    >
                        <TbLayoutDashboardFilled />
                        Dashboard 
                    </NavLink>
                    <NavLink
                        to={"/dashboard/transactions"}
                        className="hover:text-white hover:bg-[--chocolateWhite]"
                        style={({ isActive }) => (isActive ? activeStyle : undefined)}
                        end
                        onClick={handleLinkClick}
                    >
                        <MdPayments />
                        Share Reward Pool 
                    </NavLink>
                    <NavLink
                        to={"/dashboard/referral"}
                        className="hover:text-white hover:bg-[--chocolateWhite]"
                        style={({ isActive }) => (isActive ? activeStyle : undefined)}
                        end
                        onClick={handleLinkClick}
                    >
                        <FaUsers />
                        Referral 
                    </NavLink>
                    <NavLink
                        to={"/dashboard/profile"}
                        className="hover:text-white hover:bg-[--chocolateWhite]"
                        style={({ isActive }) => (isActive ? activeStyle : undefined)}
                        end
                        onClick={handleLinkClick}
                    >
                        <FaUser />
                        Profile 
                    </NavLink>
                    <button onClick={handleDisconnect}>Logout</button>
                </div>
            </div>
        </div>
    </>

}

export default Sidebar

