import React from 'react';
import { IoIosCheckmarkCircle } from "react-icons/io"
import "../../styles/Overview.css"
import { AiFillMinusCircle } from "react-icons/ai"
import { FaUsers } from "react-icons/fa6"
import UserContext from '../../context/UserContext';
import { useNavigate } from "react-router"

import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { setOverviewData, setSocialData } from '../../redux/walletSlice';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const Overview = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const userData = useSelector((state) => state.wallet.userData);
    const configData = useSelector((state) => state.wallet.configData);
    const socialTasks = useSelector((state) => state.wallet.socialTasks);
    const topSharePowerHolders = useSelector((state) => state.wallet.topSharePowerHolders);
    const { walletAddress, syncWallet, unsyncWallet, setWeb3Prop } = useContext(UserContext);
    const [renderKey, setRenderKey] = useState(0);

    const rewardedCount = socialTasks.filter(task => task.rewarded === 1).length;
    // State to track loading state for each task
    const [loadingTask, setLoadingTask] = useState(null);
    const [loadingTaskId, setLoadingTaskId] = useState(null);


    const handleVerifyTask = async (taskId) => {
        setLoadingTaskId(taskId); // Set loading state
        try {
            
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}social-tasks/verify`, { id: taskId });
            
            if (response.data[0]?.id) {
                dispatch(setSocialData(response.data));
                toast.success('Task successfully verified!');

                // Increment renderKey to force re-render
                //setRenderKey(prevKey => prevKey + 1);

                window.location.reload();
            } else {
                toast.error('Failed to verify the task.');
            }
        } catch (error) {
            
            console.error('Task verification error:', error);
        } finally {
            setLoadingTaskId(null); // Reset loading state
        }
    };


    useEffect(() => {
        const fetchOverviewData = async () => {

            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}user/overview/${walletAddress}`);
                if (response.data) {
                    dispatch(setOverviewData(response.data));
                }
            } catch (error) {
                console.error('Error fetching overview data:', error);
            }
        };

        if (walletAddress) {
            fetchOverviewData();
        }
    }, [walletAddress, dispatch]);


    // Updated social tasks with links
    const socialList = [
        { name: 'Facebook', link: "" },
        { name: 'Twitter', link: "https://x.com/Hoflambabros?t=4142jUuhSGo_OKvu24KMfQ&s=09" },
        { name: 'YouTube', link: "https://www.youtube.com/@officialHOLTv" },
        { name: 'Telegram', link: "https://t.me/houseoflambabros" },
        { name: 'Instagram', link: "https://www.instagram.com/houseoflambabros?igsh=MXBldHFmN2h1MXFiaA==" },
    ];

    const handleTaskCreation = async (task) => {
        try {
            setLoadingTask(task.name); // Set loading state for the clicked task
            // Make POST request to backend

            try {
                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}social-tasks`, {
                    wallet_address: walletAddress,
                    name: task.name,
                });
                if (response.data) {
        
                    dispatch(setSocialData(response.data));

                    //window.location.reload();
                }
            } catch (error) {
                console.error('Error fetching overview data:', error);
            }
            // Redirect to the task's social link after successful creation
            if (task.link) {
                window.open(task.link, '_blank', 'noopener,noreferrer');
                navigate('/dashboard');
            }
        } catch (error) {
            console.error('Error creating social task:', error);
        } finally {
            setLoadingTask(null); // Reset loading state
        }
    };



    // This function determines the progress and render state based on task status
    const renderTaskStatus = (task) => {
        const userTask = socialTasks.find((userTask) => userTask.name === task.name);

        if (userTask) {
            // If visited and verified
            if (userTask.verified) {
                return (
                    <div key={task.id} className="task">
                        <div className="taskTitle">
                            <p>Follow us on {task.name}</p>
                            <p><IoIosCheckmarkCircle style={{ color: 'green' }} /> Done</p>
                        </div>
                        <div className="progressBar">
                            <div className="progressLoad" style={{ width: '100%', backgroundColor: 'green' }}>100%</div>
                        </div>
                    </div>
                );
            }
            // If visited but not verified
            if (userTask.visited && !userTask.verified) {
                return (
                    <div key={task.id} className="task">
                        <div className="taskTitle">
                            <p>Follow us on {userTask.name}</p>
                            <p>
                            <button 
                        onClick={() => handleVerifyTask(userTask.id)}
                        disabled={loadingTaskId === userTask.id}
                    >
                        {loadingTaskId === userTask.id ? 'Verifying...' : 'Verify'}
                    </button>
                            </p>
                        </div>
                        <div className="progressBar">
                            <div className="progressLoad" style={{ width: '80%', backgroundColor: 'rgba(255, 210, 16, 1)' }}>80%</div>
                        </div>
                    </div>
                );
            }
            // If only visited
            if (userTask.visited) {
                return (
                    <div key={task.id} className="task">
                        <div className="taskTitle">
                            <p>Follow us on {task.name}</p>
                            <p><button>Follow us on {task.name}</button></p>
                        </div>
                        <div className="progressBar">
                            <div className="progressLoad" style={{ width: '50%', backgroundColor: 'rgba(255, 210, 16, 1)' }}>50%</div>
                        </div>
                    </div>
                );
            }
        }

        // If task does not exist in user tasks
        return (
            <div key={task.id} className="task">
                <div className="taskTitle">
                    <p>Follow us on {task.name}</p>
                    <p>
                        <button
                            disabled={loadingTask === task.name}
                            onClick={() => handleTaskCreation(task)}
                        >
                            {loadingTask === task.name ? 'Loading...' : `Follow us on ${task.name}`}
                        </button>
                    </p>
                </div>
                <div className="progressBar">
                    <div className="progressLoad" style={{ width: '1%', backgroundColor: 'rgba(255, 210, 16, 1)' }}>0%</div>
                </div>
            </div>
        );
    };


    return <>
        <div className="welcomeWrapper">
            <div className="leftWelcome">
                <h2>Welcome, {walletAddress.substring(0, 12)}{walletAddress.length >= 9 && '...'}</h2>
                <p>🚀  The first 2,000 members with 100,000 ⚡SP+ can moderate the show, cast final votes, and share the moderator reward pool.

🚀 Share Power unlock for transfer, gifting, and monetization: First 10,000 holders

Note: Every share power holder shares 50% of accumulated voting fees based on the amount of share power ⚡SP they have.</p>
            </div>
            <div className="rightWelcome">
                <img src={require("../../assets/images/coingroup.png")} alt="" />
            </div>
        </div>

        <div className="prizepoolWrapper">
            <h3>Prize pool & Share Power</h3>

            <div className="cardWrapper">

                <div className="card">
                    <div className="cardTitle">
                        <p>Prize Pool <span>+50%</span></p>
                        <img src={require("../../assets/images/prizepool.png")} alt="" />
                    </div>
                    <h2>&#8358;{Number(configData?.prize_pool).toLocaleString()}</h2>
                </div>

                <div className="card">
                    <div className="cardTitle">
                        <p>Total Share Power</p>
                        <img src={require("../../assets/images/sharepower.png")} alt="" />
                    </div>
                    <h2>
                        {userData?.share_power}
                        <img src={require("../../assets/images/flash.png")} alt="" />
                    </h2>
                </div>

                <div className="card">
                    <div className="cardTitle">
                        <p>LBT Token</p>
                        <img src={require("../../assets/images/token.png")} alt="" />
                    </div>
                    <h2>{parseFloat(userData?.lbt).toFixed(5)} <small>LBT</small></h2>
                </div>

            </div>
        </div>

        <div className="wrapper">

            <div className="leftWrapper">
                <div className="title">
                    <p>Available Tasks</p>
                    <span>({rewardedCount}/5 done)</span>
                </div>

                <div className="taskContainer">
                    <div key={renderKey} className="taskWrapper">
                        {socialList.map((task) => (
                            <React.Fragment key={task.id}>
                                {renderTaskStatus(task)}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>

            <div className="rightWrapper">
                <div className="title title2">
                    <p>Accumulate ⚡SP</p>
                </div>

                <p className="buySteps">Click below and select your preferred boost ticket. Your boost ticket determines how many SP you get. </p>

                <Link to="/dashboard/transactions">
                    <button>
                        Buy Boost Ticket
                    </button>
                </Link>
            </div>


        </div>

        <div className="prizepoolWrapper">
            <h3>Referral and Share Power Boost</h3>
            <div className="shareWrapper">
                <div className="rightWrapper">
                    <div className="title">
                        <p>About Referrals and Share Power ⚡SP</p>
                    </div>
                    <p className="buySteps">Earn an extra 10% of $LBT from your referrals as you accumulate free share power ⚡SP based on their boost.</p>

                    <Link to="/dashboard/referral">
                        <button>
                            Refer Now
                        </button>
                    </Link>
                    <div className="count">
                        <FaUsers />
                        <p>Total Referrals: {userData?.direct_referrals}</p>
                    </div>
                </div>
                <div className="leftWrapper shareleftWrapper">
                    <div className="title">
                        <p>Top Share Power Holder</p>
                    </div>

                    <div className="usersContainer">
                        <div className="usersWrapper">
                            {topSharePowerHolders.map((user, index) => (
                                <div className="users" key={user.walletAddress}>
                                    <img
                                        src={`https://ui-avatars.com/api/?name=${encodeURIComponent(
                                            user.display_name || user.wallet_address // Use displayName if available, otherwise walletAddress
                                        )}&background=FFC81E&color=fff`} // Generates avatar with yellow background (#FFC81E)
                                        alt={`${user.display_name || user.wallet_address} avatar`}
                                    />
                                    <span className="position">
                                        {index + 1}
                                        {index === 0
                                            ? "st"
                                            : index === 1
                                                ? "nd"
                                                : index === 2
                                                    ? "rd"
                                                    : "th"}
                                    </span>
                                    <h3>{user.displayName || user.walletAddress}</h3> {/* Display displayName if it exists */}
                                    <p>
                                        {user.display_name
                                            ? `${user.display_name.substring(0, 9)}${user.display_name.length > 9 ? '...' : ''}`
                                            : `${user.wallet_address.substring(0, 6)}...`}
                                    </p>
                                    <p>
                                        {user.share_power}{" "}
                                        <img
                                            src={require("../../assets/images/yellowflash.png")}
                                            alt="flash icon"
                                        />
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </>

}

export default Overview