import "../../styles/Transaction.css"

import axios from 'axios';
import { ethers } from 'ethers';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setTransactionData, setBnbRate, disconnectWallet } from '../../redux/walletSlice';
import { useContext, useEffect, useState } from "react";
import UserContext from '../../context/UserContext';
import { toast } from 'react-toastify';



const boosts = [
    { name: "Mini Boost", amount: 1, image_url: "/images/47-min.png" },
    { name: "Power Surge", amount: 2, image_url: "/images/48-min.png" },
    { name: "Hyper Charge", amount: 5, image_url: "/images/49-min.png" },
    { name: "Turbo Shot", amount: 10, image_url: "/images/50-min.png" },
    { name: "Ultra Burst", amount: 25, image_url: "/images/51-min.png" },
    { name: "Maximum Rush", amount: 50, image_url: "/images/52-min.png" },
    { name: "Shark Boost", amount: 100, image_url: "/images/53-min.png" },
    { name: "Master Whale", amount: 200, image_url: "/images/54-min.png" },
];

const Transaction = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [selectedAmount, setSelectedAmount] = useState(null);
    const [userWalletAddress, setUserWalletAddress] = useState(null);
    const [transactionHash, setTransactionHash] = useState(null);
    const { walletAddress, syncWallet, unsyncWallet, setWeb3Prop } = useContext(UserContext);

    const [loading, setLoading] = useState(false);
    const [verifyingTransaction, setVerifyingTransaction] = useState(null); // Renamed state variable

    const configData = useSelector((state) => state.wallet.configData);
    const transactionData = useSelector((state) => state.wallet.transactionData);

    // This useEffect ensures we have a wallet connected upon component load
    useEffect(() => {


        if (!walletAddress) {
            console.error("No wallet address found. Please login using WalletConnect.");
            unsyncWallet();
            // Update Redux state
            dispatch(disconnectWallet());
        }
    }, [walletAddress]);

    // Fetch BNB price on component mount
    useEffect(() => {
        const fetchBNBPrice = async () => {
            try {
                const response = await axios.get(
                    'https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd'
                );

                if (response && response.data && response.data.binancecoin && response.data.binancecoin.usd) {
                    const bnbRate = response.data.binancecoin.usd;

                    // Update the Redux state with the new BNB rate
                    dispatch(setBnbRate( bnbRate));

                    // Post updated BNB rate to the backend
                    await axios.put(`${process.env.REACT_APP_API_BASE_URL}config`, {
                        bnb_rate: bnbRate,
                    });
                } else {
                    console.error('Invalid response format:', response);
                }

            } catch (error) {
                console.error('Error fetching BNB price:', error);
            }
        };

        fetchBNBPrice();
    }, []);

    useEffect(() => {
        const fetchTransactions = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}transaction/${walletAddress}`);
                dispatch(setTransactionData(response.data)); // Assuming response data is an array of transactions
            } catch (error) {
                console.error('Error fetching transactions:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchTransactions();
    }, []);

    
    // Handle payment
    const handlePayment = async (amount, index, boostName) => {
        setLoading(index); // Set loading for the clicked button

        if (!walletAddress) {
            alert("Please login using WalletConnect first.");
            return;
        }

        try {
            await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: "0x38" }], // Hexadecimal for 56 (Mainnet)
              });
            // Create a provider using the existing connected wallet
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();

            

            // Convert USD amount to BNB
            const bnbAmount = amount / configData.bnb_rate;

            // Add a small fee (e.g., 1% of BNB amount)
            const fee = bnbAmount * 0.01;
            const finalAmount = bnbAmount + fee;

            // Convert final amount to string and parse it using ethers
            const finalBNBAmount = ethers.utils.parseEther(finalAmount.toFixed(18));

            
            const tx = await signer.sendTransaction({
                to: `${process.env.REACT_APP_WALLET_ADDRESS}`,
                value: finalBNBAmount,
            });

            setTransactionHash(tx.hash);

            try {
                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}transaction`, {
                    walletAddress: walletAddress,
                    amount,
                    bnbAmount:finalAmount,
                    boostName: boostName,
                    transactionHash: tx.hash,
                });
                if (response.data) {
                    console.log(response.data)
                    dispatch(setTransactionData(response.data));
                }
            } catch (error) {
                const hashMessage = tx?.hash ? `Transaction Hash: ${tx.hash}\nPlease copy this hash and contact support if your transaction is not acknowledged.` : 'An error occurred during the transaction. Please try again later.';
                window.alert(hashMessage);
                console.error('Error fetching overview data:', error);
            }

            toast.info('Transaction Successful!', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            console.log("Transaction passed", tx.hash);

        } catch (error) {
            console.error("Transaction failed", error);
            //alert("Transaction failed: " + error.message);
            toast.error("Transaction failed: " + error.message);
        }

        // Simulate an async operation (e.g., API call)
        setTimeout(() => {
            //console.log(`Paid $${amount}`);
            setLoading(null); // Reset loading
        }, 2000);
    };

    const sendPayment = async () => {
        alert("hello")
        try {
            console.log('Initializing payment...');
            if (!window.ethereum) {
                console.error('No wallet detected');
                return;
            }

            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();

            const transaction = {
                to: '0xRecipientAddressHere', // Replace with your recipient address
                value: ethers.utils.parseEther('0.1'),
            };

            console.log('Sending transaction...');
            const txResponse = await signer.sendTransaction(transaction);
            console.log('Transaction sent:', txResponse);

            const receipt = await txResponse.wait();
            console.log('Transaction confirmed:', receipt);
        } catch (error) {
            console.error('Transaction error:', error);
        }
    };

    // Function to handle the verification of a transaction
    const handleVerifyOld = async (transactionId) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}transaction/${transactionId}/verify`, {
                id: transactionId,
            });

            console.log(response.data[0]?.id);
            if (response.data[0]?.id) {
                
                console.log(response.data)
                dispatch(setTransactionData(response.data));
                
                toast.success('Transaction successfully verified.');
                // Optionally, you can trigger a re-fetch or update state to reflect the changes
                //navigate('/dashboard');

            }
        } catch (error) {
            toast.error('An error occurred while verifying the transaction.');
            console.error('Error verifying transaction:', error);
        }
    };

    // Function to handle the verification of a transaction
    const handleVerify = async (transactionId) => {
        try {
            // Set the verifying state for the current transactionId
            setVerifyingTransaction(transactionId);

            // Make the API request
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}transaction/${transactionId}/verify`, {
                id: transactionId,
            });

            // Handle the response
            if (response.data[0]?.id) {
                dispatch(setTransactionData(response.data));
                toast.success('Transaction successfully verified.');
            }
        } catch (error) {
            toast.error('An error occurred while verifying the transaction.');
            console.error('Error verifying transaction:', error);
        } finally {
            // Reset verifying state
            setVerifyingTransaction(null);
        }
    };

    return <>
        <h2 className="transactions">Share Reward Pools</h2>

        <div className="boostContainer">
            <h3>Buy Boosts</h3>

            <div className="boostWrapper">

                {boosts.map((boost, index) => (
                    <div
                        className="boost"
                        key={index}
                        style={{
                            backgroundImage: `url(${boost.image_url})`,
                        }}
                    >
                        <div className="boostOverlay">
                            <div className="boostTitle">
                                <p>{boost.name}</p>
                            </div>
                            <h1>${boost.amount}</h1>
                            <button
                                onClick={() => handlePayment(boost.amount, index, boost.name)}
                                disabled={loading === index}
                            >
                                {loading === index ? "Loading..." : "Buy Boost"}
                            </button>
                        </div>
                    </div>
                ))}

            </div>
        </div>


        <div className="historyWrapper">
            <h3>Transaction History</h3>
            <div className="tableWrapper">
                <table>
                    <thead>
                        <tr>
                            <th>Transaction ID</th>
                            <th>Boost Name</th>
                            <th>Amount</th>
                            <th>Date</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactionData.length === 0 ? (
                            <tr>
                                <td colSpan="5">No transactions available</td>
                            </tr>
                        ) : (
                            transactionData.map((transaction) => (
                                <tr key={transaction.id}>
                                    <td>
                                        <a
                                            href={`https://bscscan.com/tx/${transaction.txnHash}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                          <b> {transaction.txnHash.substring(0, 9)} {transaction.txnHash.length >= 9 && '...'}</b>
                                        </a>
                                    </td>
                                    <td>{transaction.name}</td>
                                    <td>${transaction.amount}</td>
                                    <td>{new Date(transaction.createdAt).toLocaleDateString()}</td>
                                    <td>
                                        {!transaction.verified ? (
                                            <button className="verifyButton" 
                                            onClick={() => handleVerify(transaction.id)}
                                            disabled={verifyingTransaction === transaction.id} // Disable button while verifying
                                        >
                                            {verifyingTransaction === transaction.id ? 'Loading...' : 'Verify'}
                                        </button>
                                            
                                        ) : (
                                            <span
  style={{
    display: 'inline-block',
    padding: '0.2em 0.6em',
    fontSize: '0.75rem',
    fontWeight: '600',
    color: '#fff',
    backgroundColor: '#28a745', // Bootstrap success green
    borderRadius: '0.25rem'
  }}
>
  verified
</span>
                                        )}
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </div>

    </>

}

export default Transaction