import { BiSolidCopy } from "react-icons/bi"
import "../../styles/Referral.css"

import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import UserContext from '../../context/UserContext';

import axios from 'axios';
import { setReferralData } from '../../redux/walletSlice';


const Referral = () => {
    const dispatch = useDispatch();

    const userData = useSelector((state) => state.wallet.userData);
    const configData = useSelector((state) => state.wallet.configData);
    const referralData = useSelector((state) => state.wallet.referralData);
    const topSharePowerHolders = useSelector((state) => state.wallet.topSharePowerHolders);
    const { walletAddress, syncWallet, unsyncWallet, setWeb3Prop } = useContext(UserContext);

    const [loading, setLoading] = useState(true);

    // Get the base URL from environment variables
    const baseUrl = process.env.REACT_APP_BASE_URL || 'https://example.com/referral';

    // Create the full referral link using userData.reflink
    const referralLink = `${baseUrl}?ref=${userData.reflink}`;

    // Function to copy the referral link to clipboard
    const handleCopy = () => {
        navigator.clipboard.writeText(referralLink)
            .then(() => {
                // Show success message with react-toastify
                toast.success('Referral link copied to clipboard!');
            })
            .catch((err) => {
                console.error('Error copying referral link: ', err);
                toast.error('Failed to copy referral link');
            });
    };

    useEffect(() => {
        // Example: Fetch data from API or from Redux store
        const fetchReferrals = async () => {

            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}referral/${walletAddress}`);
                if (response.data) {
                    dispatch(setReferralData(response.data));
                }
            } catch (error) {
                console.error('Error fetching referrals:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchReferrals();
    }, []);

    return <>
        <h2 className="referrals">Referrals</h2>

        <div className="cardWrapper">

            <div className="card">
                <div className="cardTitle">
                    <p>Direct Referrals</p>
                    <img src="" alt="" />
                </div>
                <h2>{userData?.direct_referrals}</h2>
            </div>

            {
                userData.community ? (
                    <div className="card">
                        <div className="cardTitle">
                            <p>Indirect Referrals</p>
                            <img src="" alt="" />
                        </div>
                        <h2>
                        {userData?.indirect_referrals}
                        </h2>
                    </div>

                ) : (
                    <p className="alignCenter"></p>
                )
            }
            <div className="card">
                <div className="cardTitle">
                    <p>LBT Earnings</p>
                    <img src={require("../../assets/images/token.png")} alt="" />
                </div>
                <h2>{parseFloat(userData?.lbt).toFixed(5)} <small>LBT</small></h2>
            </div>

        </div>

        <div className="boostReferrals">
            <div className="boostReferralOverlay">
                <h3>Boost your Share Power with Referrals </h3>
                <p>Did you know? Every time your referral powers up with any Share Power category, your Share Power gets an instant boost too! Earn more, grow faster, and maximize your rewards effortlessly. Start sharing today and watch your Share Power multiply!</p>

                <div className="referralLink">
                    <p><b>Referral Link:</b> {referralLink}</p>
                    <CopyToClipboard text={referralLink} onCopy={() => toast.success('Referral link copied to clipboard!')}>
                        <button>
                            <BiSolidCopy />
                        </button>
                    </CopyToClipboard>
                </div>
            </div>
        </div>

        <div className="alertContainer">
      <div className="alertBox">
        <h4 className="alertTitle">Become a Community Head!</h4>
        <p className="alertBody">
        TheGenesis is a Community inclusive ecosystem, where a community can support their own in the house. To become a Community Head and get paid to support your community activities 
          <a href="https://wa.link/bttaey" target="_blank" rel="noopener noreferrer"> Find out more here.</a>
        </p>
      </div>
    </div>

        <div className="referralList">
            <h3>Referral List</h3>
            <div className="wrapper ">

                <div className="leftWrapper">
                    <div className="title">
                        <p>Direct Referrals</p>
                    </div>

                    <div className="taskContainer">
                        <div className="taskWrapper">
                            {loading ? (
                                <p>Loading...</p> // You can show a loading state while fetching data
                            ) : referralData?.direct.length === 0 ? (
                                <div className="referral">
                                    <div className="referralInfo">

                                        <p>No referral data found</p>
                                    </div>
                                    <div className="referrDate">
                                        <small></small>
                                        <span></span>
                                    </div>
                                </div>
                            ) : (
                                referralData?.direct.map((referral, index) => (
                                    <div key={index} className="referral">
                                        <div className="referralInfo">
                                        <img
                                                        src={`https://ui-avatars.com/api/?name=${encodeURIComponent(referral.referred.wallet_address)}&background=FFC81E&color=fff`}
                                                        alt={referral.referred.wallet_address.substring(0, 15)}
                                                    />
                                            <p><b>{referral.referred.wallet_address.substring(0, 15)}..</b></p>
                                        </div>
                                        <div className="referrDate">
                                            <small>Referral Date</small>
                                            <span>{new Date(referral.created_at).toLocaleDateString()}</span>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>


                {
                    userData.community ? (
                        <div className="leftWrapper">
                            <div className="title">
                                <p>Indirect Referrals</p>
                            </div>

                            <div className="taskContainer">
                                <div className="taskWrapper">
                                    {referralData?.indirect.length > 0 ? (
                                        referralData?.indirect.map((referral, index) => (
                                            <div className="referral" key={index}>
                                                <div className="referralInfo">
                                                    {/* Dynamically generate image URL */}
                                                    <img
                                                        src={`https://ui-avatars.com/api/?name=${encodeURIComponent(referral.referred.wallet_address)}&background=FFC81E&color=fff`}
                                                        alt={referral.name}
                                                    />
                                                    <p><b>{referral.referred.wallet_address.substring(0, 15)}..</b></p>
                                                </div>
                                                <div className="referrDate">
                                                    <small>Referral Date:</small>
                                                    <span>{new Date(referral.created_at).toLocaleDateString()}</span>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="referral">
                                    <div className="referralInfo">

                                        <p>No indirect referral data found</p>
                                    </div>
                                    <div className="referrDate">
                                        <small></small>
                                        <span></span>
                                    </div>
                                </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <p className="alignCenter"></p>
                    )
                }



            </div>
        </div>


        <div className="powerHolderWrapper">
            <h3>Top Share Power Holder</h3>

            <div className="powerHolders">
                <div className="powerUsersContainer">
                    {topSharePowerHolders && topSharePowerHolders.length > 0 ? (
                        <div className="powerUsersWrapper">
                            {topSharePowerHolders.map((user, index) => (
                                <div className="users" key={user.walletAddress}>
                                    <img
                                        src={`https://ui-avatars.com/api/?name=${encodeURIComponent(
                                            user.display_name || user.wallet_address // Use display_name if available, otherwise wallet_address
                                        )}&background=FFC81E&color=fff`} // Generates avatar with yellow background (#FFC81E)
                                        alt={`${user.display_name || user.wallet_address} avatar`}
                                    />
                                    <span className="position">
                                        {index + 1}
                                        {index === 0 ? "st" : index === 1 ? "nd" : index === 2 ? "rd" : "th"}
                                    </span>
                                    <h3>{user.display_name
                                            ? `${user.display_name.substring(0, 9)}${user.display_name.length > 9 ? '...' : ''}`
                                            : `${user.wallet_address.substring(0, 6)}...`}</h3> {/* Display display_name if available */}
                                    
                                    <p>
                                        {user.share_power}{" "}
                                        <img
                                            src={require("../../assets/images/yellowflash.png")}
                                            alt="flash icon"
                                        />
                                    </p>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p>No data available</p> // Display message when no data exists
                    )}
                </div>
            </div>
        </div>



    </>

}

export default Referral